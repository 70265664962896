.header-container{
    display: flex;
    justify-content: center;
    height: 149px;
    color: #ffffff;   
}

.header-parent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 70%;
    color: #ffffff;
}

.header-logo{
    font-size: 50px;
    font-family: "Poppins ExtraBold";
    color: #ffffff;
}

.header-options{
    display: flex;
    align-items: center;
}

.header-option{
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
}

.header-hamburger{
    display: none;
    color: #ffffff;
}

.header-option-seperator{
    margin: 0 50px 0 0;
    color: #ffffff;
}

.selected-header-option{
    /* color: #ff5823; */
    color: #FF5823;
}

@media only screen and (max-width: 1110px){
    .header-parent{
        /* display:block; */
        width: 80%;
    }
}

@media only screen and (max-width: 970px){
    .header-hamburger{
        display:block;
    }
    .header-parent{
        width:100%;
        padding: 0 40px;
        position: relative;
    }
    .header-options{
        display: flex;
        flex-direction: column;
        position: absolute;
        left: -100%;
        top: 0px;
        height: 100vh;
        width: 100%;
        justify-content: space-around;
        background-color: #1f2235;
        z-index: 1000;
        opacity: 0.5;
        transition: all 0.8s ease-out;
    }
    .header-option-seperator{
        margin: 0;
    }
    .selected-header-option{
        color: #ffffff;
    }
    /* .header-option{
        color: #ffffff;
    } */
    .header-hamburger-bars{
        font-size: 36px;
        cursor: pointer;
        margin: 0px 10px;
        color: #ffffff;
    }
    .show-hamburger-options{
        left:0;
        opacity: 1;
    }
}